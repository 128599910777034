import '../../components/fontawesome-5.5/css/all.min.css'
import '../../components/css/tooplate-infinite-loop.css'
import '../../components/scripts/scripts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFile, faGavel, faHandshake, faHeadset, faSignature, faUserTie } from '@fortawesome/free-solid-svg-icons';
import logo from '../../components/img/logo/AyA.png'

const Insolvency = ({ FooterElement }) => {

  return (
    <div className="">
      <section id="infinite-insolvency" className="text-white tm-font-big tm-parallax">
        <nav className="navbar navbar-expand-md tm-navbar" id="tmNav">
          <div className="container">
            <div className="tm-next">
              <a href="#infinite" className="navbar-brand">Ariza y Asociados SAS</a>
            </div>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <i className="fas fa-bars navbar-toggler-icon"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link tm-nav-link" href="#infinite">Inicio</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link tm-nav-link" href="#whatwedo">Acerca de</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link tm-nav-link" href="#how-works-infinite">Como funciona?</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link tm-nav-link" href="#faq">FAQ</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link tm-nav-link" href="#contact">Contactanos</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="text-center tm-hero-text-container">
          <div className="tm-hero-text-container-inner">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <br />
            <h2 className="tm-hero-subtitle">
              Asesorias en Insolvencias
              <br />Abogados
            </h2>
          </div>
        </div>

        <div className="tm-next tm-intro-next">
          <a href="#whatwedo" className="text-center tm-down-arrow-link">
            <i className="fas fa-1x fa-arrow-down tm-down-arrow"> Iniciar</i>
          </a>
        </div>
      </section>
      <section id="whatwedo" className="tm-section-pad-top">
        <div className="container">
          <div className="row tm-content-box">
            <div className="col-lg-12 col-xl-12">
              <div className="tm-intro-text-container">
                <h2 className="tm-text-primary mb-4 tm-section-title">¿Problemas con grandes deudas?</h2>
                <p className="mb-4 tm-intro-text">
                  Acógete a la <strong>Ley de Insolvencia</strong> y recupera tu tranquilidad,
                  si tienes dificultades para pagar tus obligaciones, la <strong>Ley 1116 de Insolvencia en Colombia</strong> te ofrece una solución legal para negociar con tus acreedores y evitar embargos. ¡Te ayudamos a lograrlo!
                  <strong><a href="#contact"> Solicitar Asesoría Gratis</a></strong> </p>
              </div>
            </div>
          </div>

          <div className="row tm-content-box">
            <div className="col-lg-12 col-xl-12">
              <div className="tm-intro-text-container">
                <h2 className="tm-text-primary mb-4 tm-section-title">¿Qué es la Ley de Insolvencia?</h2>
                <p className="mb-4 tm-intro-text">
                  La Ley de Insolvencia en Colombia es un mecanismo legal que permite a personas naturales y jurídicas negociar sus deudas con los acreedores, evitando embargos y logrando acuerdos de pago justos. <br />Con ello tienes los siguientes <strong>BENEFICIOS: </strong></p>
              </div>
            </div>
          </div>
          <div className="row tm-content-box">
            <div className="col-lg-4">
              <div className="tm-intro-text-container">
                <i className="far fa-3x fa-check-circle text-center tm-icon"></i>
                <p className="mb-4 tm-intro-text">
                  <center>Suspensión de embargos y procesos judiciales.</center>
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tm-intro-text-container">
                <i className="far fa-3x fa-check-circle text-center tm-icon"></i>
                <p className="mb-4 tm-intro-text">
                  <center>Reducción de intereses y refinanciación de deudas.</center>
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tm-intro-text-container">
                <i className="far fa-3x fa-check-circle text-center tm-icon"></i>
                <p className="mb-4 tm-intro-text">
                  <center>Protección del patrimonio personal y empresarial.</center>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="how-works-insolvency" className="tm-section-pad-top tm-parallax-2">
        <div className="container tm-testimonials-content">
          <div className="row">
            <div className="col-lg-12 tm-content-box">
              <h2 className="text-white text-center mb-4 tm-section-title">¿Cómo funciona el proceso? </h2>
              <p className="mx-auto tm-section-desc text-center">
                Con <strong>Ariza y Asociados SAS</strong> te facilitamos la vida, en estos simples pasos puedes acceder al proceso de insolvencia
              </p>
              <div className="mx-auto tm-gallery-container tm-gallery-container-2">
                <div className="row">
                  <div className="body-card col-sm-3 text-center">
                    <div>
                      <div className="sbt-icon">
                        <FontAwesomeIcon scale={'10px'} icon={faCheck} />
                      </div>
                      <div className="sbt-title">1. Diagnóstico inicial del caso</div>
                      <div className="sbt-text">Evaluamos tu situación financiera</div>
                    </div>
                  </div>

                  <div className="body-card col-sm-3 text-center">
                    <div>
                      <div className="sbt-icon">
                        <FontAwesomeIcon scale={'10px'} icon={faFile} />
                      </div>
                      <div className="sbt-title">2. Presentación de solicitud</div>
                      <div className="sbt-text">Documentamos tu caso ante el centro de conciliación.</div>
                    </div>
                  </div>

                  <div className="body-card col-sm-3 text-center">
                    <div>
                      <div className="sbt-icon">
                        <FontAwesomeIcon scale={'10px'} icon={faUserTie} />
                      </div>
                      <div className="sbt-title">3. Negociación con acreedores</div>
                      <div className="sbt-text">Buscamos acuerdos de pago viables.</div>
                    </div>
                  </div>

                  <div className="body-card col-sm-3 text-center">
                    <div>
                      <div className="sbt-icon">
                        <FontAwesomeIcon scale={'10px'} icon={faSignature} />
                      </div>
                      <div className="sbt-title">4. Plan de pago aprobado</div>
                      <div className="sbt-text">Se establece un esquema de pagos legalmente respaldado.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <a href="#whatwedo" className="text-center tm-down-arrow-link">
            <i className="fas tm-down-arrow"> Contáctanos</i>
          </a>
        </div>
      </section>
      <section id="faq" className="tm-section-pad-top">
        <div className="container">
          <div className="row tm-content-box">
            <div className="col-lg-12 col-xl-12">
              <div className="tm-intro-text-container">
                <h2 className="tm-text-primary mb-4 tm-section-title">Preguntas Frecuentes</h2>
                <p className="mb-4 tm-intro-text">
                  <strong>¿Cuánto tiempo dura el proceso de insolvencia?</strong> <br />
                  Depende del caso, pero en promedio puede durar entre 3 y 6 meses.</p>

                  <p className="mb-4 tm-intro-text">
                  <strong>¿Voy a perder mis bienes?</strong> <br />
                  No necesariamente. La ley busca proteger el patrimonio y evitar embargos.</p>

                  <p className="mb-4 tm-intro-text">
                  <strong>¿Esta ley aplica solo para empresas?</strong> <br />
                  No, aplica para personas naturales y empresarios individuales.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterElement />
    </div>
  )
}

export default Insolvency;