import { useEffect, useState } from 'react';
import LoaderItem from "../../services/LoaderItem";
import PriceFormatter from '../priceFormatter/PriceFormater';
import '../../components/fontawesome-5.5/css/all.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faRuler, faBed, faBath } from '@fortawesome/free-solid-svg-icons';
import '../../components/css/tooplate-infinite-loop.css'
import UpdaterCounter from '../../utils/counter';
import config from '../../config';
const domain = config.api_domain.domain
const domainFront = config.api_domain.domainFront

const IteratorServices = ({ num }) => {
  const url = `${domain}/house/list/auction`;
  const [cleanDataDetail, setCleanDataDetail] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const items = await LoaderItem(url);
        setCleanDataDetail(items);
      } catch (error) {
        console.error('Error al cargar los datos:', error);
        setCleanDataDetail([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (!cleanDataDetail || cleanDataDetail.length === 0 || cleanDataDetail === ![]) {
    return <p className="text-center">Sin oportunidades de inversión disponibles aún</p>;
  }

  return (
    <div className='row'>
      {[...cleanDataDetail]
        .reverse()
        .filter(item => {
          const auctionDate = new Date(item.auction_date); 
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return item.state === 1 && auctionDate > today;
        })
        .slice(0, num)
        .map((item, index) => {
          return (
            <a
              className='col-lg-4'
              key={index}
              href={`${domainFront}/item_service/${item.id}`}
              onClick={(event) => {
                event.stopPropagation();
                UpdaterCounter(item.id, `${item.title}: ${item.city}, ${item.sector}`);
              }}
            >
              <div className='custom-card mt-5'>
                <br />
                <div className="custom-card-body">
                  <center>
                    <div className="location">
                      <strong><FontAwesomeIcon icon={faMapMarker} /> {item.city}</strong>
                    </div>
                    <h3 className="custom-title"><strong>{item.type}</strong></h3>
                    <div style={{ fontSize: '15px', marginBottom: "5px" }}>Código: PR-{item.id}</div>
                    <div className="date">
                      Fecha del proceso <strong>{item.auction_date}</strong>
                    </div>
                  </center>
                  <hr />
                  <div>
                    <div className='card-item'><FontAwesomeIcon className='blue-item' icon={faRuler} /> mt2: <strong><PriceFormatter value={item.mt2} /></strong></div>
                    <div className='card-item'><FontAwesomeIcon className='blue-item' icon={faBed} /> Habitaciones: {item.rooms === 0 ? <>N/A</> : item.rooms}</div>
                    <div className='card-item'><FontAwesomeIcon className='blue-item' icon={faBath} /> Baños: {item.bathrooms === 0 ? <>N/A</> : item.bathrooms}</div>
                  </div>
                  <br />
                  <div className="custom-price">
                    <div className="price">Avalúo comercial: $<strong><PriceFormatter value={item.comercial_price} /></strong></div>
                    <div className="discount" style={{ fontSize: '15px' }}>Postura mínima <PriceFormatter value={item.minimal} />%</div>
                    <div className="final-price">$<strong><PriceFormatter value={(item.comercial_price * (item.minimal / 100))} /></strong> </div>
                  </div>
                  <div className="custom-btn">
                    <p>Ver detalle</p>
                  </div>
                </div>
              </div>
            </a>
          );
        })}
    </div>
  )
};


export default IteratorServices;
