import { Outlet } from 'react-router-dom';
import '../../components/css/tooplate-infinite-loop.css'

const Admin = () => {

    return (
        <section>
                    <nav className="navbar navbar-expand-md" style={{backgroundColor: '#276285'}} id="tmNav">
        <div className="container">
            <div className="tm-next">
                <a href="./control" className="navbar-brand">Inicio</a>
            </div>
            
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fas fa-bars navbar-toggler-icon"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link tm-nav-link" href="/admin/list">Ventas</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link tm-nav-link" href="/admin/list_auction">Remates</a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link tm-nav-link" href="/admin/comunications">Comunicaciones</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link tm-nav-link" href="/admin/tools">Herramientas</a>
                </li> */}
              </ul>
            </div>
        </div>
        </nav>
        <br /><br />
        <Outlet/>
        </section>
       )
     }

export default Admin;