import { useEffect, useState } from 'react';
import '../../components/css/tooplate-infinite-loop.css';
import LoaderItem from "../../services/LoaderItem";
import config from '../../config';

const ListAuction = () => {
    const domain = config.api_domain.domain;
    const url = `${domain}/house/list/auction`;
    const [cleanDataDetail, setCleanDataDetail] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const item = await LoaderItem(url);
                setCleanDataDetail(item);
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };

        fetchData();
    }, [url]);

    if (!cleanDataDetail || cleanDataDetail.length === 0) {
        return <div>Sin elementos disponibles</div>;
    }

    return (
        <div className='container'>
            <h2 className='tm-text-primary mb-4'>Lista de remates</h2>
            <a href="/admin/new_auction">
                <button className='btn btn-primary'>Nuevo remate</button>
            </a>
            <br />
            <br />
            <table>
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Tipo</th>
                        <th>Ciudad</th>
                        <th>Locación</th>
                        <th>Fecha del proceso</th>
                        <th>Estado</th>
                        <th>Opciones</th>
                    </tr>
                </thead>
                <tbody>
                    {[...cleanDataDetail].reverse().map((item, index) => {
                        const auctionDate = new Date(item.auction_date);
                        const today = new Date();
                        today.setHours(0, 0, 0, 0); // Normalizar la fecha actual
                        const isExpired = auctionDate < today; // Verificar si la fecha ya pasó

                        return (
                            <tr className='m-3' key={index}>
                                <td>PR-{item.id}</td>
                                <td>{item.type}</td>
                                <td>{item.city}</td>
                                <td>{item.location}</td>
                                <td style={isExpired ? { color: "red", fontWeight: "bold" } : {}}>
                                    {item.auction_date}
                                </td>
                                <td style={item.state === 1 ? { color: "blue" } : { color: "red" }}>
                                    <strong>{item.state === 1 ? 'Activo' : 'Inactivo'}</strong>
                                </td>
                                <td>
                                    <a href={`/admin/update_auction/${item.id}`}>Editar</a>
                                    <br />
                                    <a href={`/admin/process/${item.id}`}>Procesos</a>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default ListAuction;
